
<template>
<div>
  test
</div>
</template>

<script>
export default {
  name: "UserProfile",
  data: () => ({
  }),
};
</script>
